import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import { getter } from "../api";
import { News, Option, OrganizationStub, Success } from "../api/types";
import Avatar from "./Avatar";
import SpinnerCustom from "./SpinnerCustom";
import { dateFormatDDMMMYYYY } from "./Utils/commons";

const Tooltip = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className={`absolute z-top ${className} max-w-xs break-all top-full invisible group-hover:visible inline-block py-1 px-2 text-xs w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip`}
    >
      {value}
    </div>
  );
};

const LatestNews = () => {
  const [entitySelected, setEntitySelected] = useState<Option>({
    label: "All",
    value: "all",
  });

  const [entityOptions, setEntityOptions] = useState<Option[]>([]);

  const { data: vertexEntities } = useSWR<Success<OrganizationStub[]>>(
    `/api/people_map/organizations/vertex_entities`,
    getter
  );

  const { data: latest_news } = useSWR<Success<News[]>>(
    `/api/people_map/news?dashboard=True&limit=50${
      entitySelected.value === "all"
        ? ""
        : `&vertex_entity=${entitySelected.value}`
    }`,
    getter
  );

  useEffect(() => {
    if (vertexEntities && vertexEntities.data.length > 0) {
      setEntityOptions([
        ...[
          {
            label: "All",
            value: "all",
          },
        ],
        ...vertexEntities.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }
  }, [vertexEntities]);

  useEffect(() => {
    const dashboardLatestNews =
      localStorage.getItem("dashboardLatestNews") ?? "";
    if (dashboardLatestNews.length > 0 && JSON.parse(dashboardLatestNews)) {
      setEntitySelected(JSON.parse(dashboardLatestNews));
    }
  }, []);

  return (
    <div className="bg-white shadow-sm p-4 !pr-0 rounded-lg border border-1 lg:h-[60%] sm:w-1/2 lg:w-full whitespace-normal">
      <div className="flex flex-row justify-between items-center my-3">
        <div className="flex flex-row ">
          <span className="h-5 w-2 mr-2 bg-blue-menu"></span>
          <div className="font-bold text-sm sm:text-xs md:text-sm mr-2">
            News
          </div>
        </div>
        <div className="relative mr-3">
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={`flex flex-row items-center justify-between bg-slate-100 gap-x-2 rounded-md px-3 py-2 min-w-[100px]
                      ${open ? "" : "text-opacity-90"} focus:outline-none
                      `}
                >
                  <div className="text-xs text-gray-900 font-semibold">{`${entitySelected.label}`}</div>
                  <ChevronDownIcon className="h-4 w-4" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="z-top absolute w-max top-10 right-0 ">
                    {entityOptions.length > 0 && (
                      <div className="p-2 rounded-lg bg-white border text-xs border-gray-200 overflow-y-auto h-fit">
                        {entityOptions.map((item: Option) => (
                          <div
                            key={item.value}
                            className={`flex items-center rounded py-2 px-4 cursor-pointer ${
                              entitySelected.value === item.value
                                ? "text-white bg-blue-menu"
                                : "hover:bg-gray-100"
                            }`}
                            onClick={() => {
                              setEntitySelected(item);
                              localStorage.setItem(
                                "dashboardLatestNews",
                                JSON.stringify(item)
                              );
                              close();
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
      <div className="flex flex-col sm:h-[92%] !h-fit max-h-[92%] overflow-y-auto pr-6 show-scrollbar ">
        {latest_news && latest_news.data ? (
          latest_news.data.length === 0 ? (
            <div className="grid justify-center">
              <div className="flex flex-col items-center">
                <img
                  src="/no-result-search.png"
                  alt="No Opportunities Found"
                  className="-mt-10 md:-mt-16 w:48 h-48 md:w-96 md:h-96"
                />
                <div className="text-xs md:text-base font-semibold -mt-4 md:-mt-12">
                  No News
                </div>
              </div>
            </div>
          ) : (
            latest_news.data.map((latest_new: News) => (
              <div
                key={latest_new.id}
                className="border-b-[1px] border-gray-300 py-2"
              >
                <div className="flex flex-row gap-x-2 items-center">
                  {latest_new.organizations.map((org) => (
                    <Link
                      href={`/organizations/${org.id}`}
                      key={org.id}
                      className="group relative"
                    >
                      <Avatar
                        name={org.name}
                        size={50}
                        url={org.image_url}
                        className="shadow-sm rounded-none"
                      />
                      <Tooltip value={org.name} className="left-0" />
                    </Link>
                  ))}
                  <Link
                    href={latest_new.url}
                    className="w-full"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex flex-col relative">
                      <span className="line-clamp-2 text-black text-xs font-semibold text-left break-normal">
                        {latest_new.title}
                      </span>
                      <div className="flex flex-row justify-between font-light text-black text-2xs">
                        <div>{latest_new.source}</div>
                        <div>
                          {dateFormatDDMMMYYYY(latest_new.publish_date)}
                        </div>
                      </div>
                      <Tooltip
                        value={latest_new.url}
                        className="left-1/2 right-1/2 -translate-x-1/2"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))
          )
        ) : (
          <SpinnerCustom />
        )}
      </div>
    </div>
  );
};

export default LatestNews;
