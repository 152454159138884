import {
  BuildingOffice2Icon,
  CircleStackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import useSWR from "swr";
import { getter } from "../../api";
import { DashboardStatistics, Success } from "../../api/types";
import SpinnerCustom from "../SpinnerCustom";
import { options } from "./DashboardUltils";

export const DataOverview = () => {
  const { data, isValidating } = useSWR<Success<DashboardStatistics>>(
    "/api/people_map/dashboard/count",
    getter,
    options
  );

  return (
    <div
      className={`bg-white shadow-sm rounded-lg border border-1 w-full flex flex-col sm:flex-row min-w-fit ${
        isValidating ? "p-0" : "p-4"
      } px-10 sm:px-2 xl:px-16 items-start sm:items-center gap-x-1 gap-y-2 whitespace-nowrap flex-nowrap md:flex-wrap justify-center sm:justify-between`}
    >
      {isValidating ? (
        <div className="flex w-full h-[192px] sm:h-20 items-center justify-center">
          <SpinnerCustom className="sm:!h-20 sm:!w-48" />
        </div>
      ) : (
        <>
          <Link
            href={"/discover/organizations"}
            passHref={true}
            className="text-black"
          >
            <div className="flex-1 flex gap-x-1">
              <div className="w-12 h-12">
                <CircleStackIcon className="w-12 h-12 text-blue-menu" />
              </div>
              <div className="flex flex-col items-start font-bold ">
                <div className="text-xl">
                  {data
                    ? Number(
                        data?.data?.organization_count?.total
                      ).toLocaleString()
                    : "N/A"}
                </div>
                <div className="text-xs">Companies in Database</div>
              </div>
            </div>
          </Link>
          <Link
            href={"/discover/organizations"}
            passHref={true}
            className="text-black"
          >
            <div className="flex-1 flex gap-x-1">
              <div className="w-12 h-12">
                <BuildingOffice2Icon className="w-12 h-12 text-blue-menu" />
              </div>
              <div className="flex flex-col items-start font-bold ">
                <div className="text-xl">
                  {data ? data?.data?.total_organization_connections : "N/A"}
                </div>
                <div className="text-xs">Company Connections</div>
              </div>
            </div>
          </Link>
          <Link
            href={"/discover/people"}
            passHref={true}
            className="text-black"
          >
            <div className="flex-1 flex gap-x-1">
              <div className="w-12 h-12">
                <UsersIcon className="w-12 h-12 text-blue-menu" />
              </div>
              <div className="flex flex-col items-start font-bold ">
                <div className="text-xl">
                  {data ? data?.data?.total_people_connections : "N/A"}
                </div>
                <div className="text-xs">People Connections</div>
              </div>
            </div>
          </Link>
        </>
      )}
    </div>
  );
};
