import React from "react";
import { useCurrentUser } from "./AuthProvider";
import Avatar from "./Avatar";
import { Companies } from "./Dashboard/Companies";
import { DashboardUpdates } from "./Dashboard/DashboardUpdates";
import { DataOverview } from "./Dashboard/DataOverview";
import { NetWorkCollaboration } from "./Dashboard/NetWorkCollaboration";
import LatestNews from "./LatestNews";

const Dashboard: React.FC = () => {
  const { user: currentUser } = useCurrentUser();

  return (
    <div className="flex w-full h-[90vh] px-6">
      <div className="flex flex-col w-full overflow-auto lg:overflow-hidden">
        <div className="flex flex-row items-center mb-5">
          <div className="ml-4 mr-4">
            <Avatar
              name={currentUser.firstName}
              size={30}
              className="lg:hidden"
              url={currentUser.image_url}
            />
            <Avatar
              name={currentUser.firstName}
              size={40}
              className="hidden lg:flex"
              url={currentUser.image_url}
            />
          </div>
          <div className="text-3xl font-bold">
            Hello, {currentUser.firstName}!
            <div className="bg-blue-menu rounded-full h-1 w-24" />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-3 md:h-full max-h-[78vh]">
          <div className="flex flex-col gap-y-3 lg:w-2/3">
            <DataOverview />
            <div className="flex flex-col w-full h-full md:h-[calc(78vh-98px)] gap-y-3 sm:gap-x-3 sm:flex-row">
              <Companies />
              <DashboardUpdates />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row lg:flex-col min-w-[270px] h-full max-h-[82vh] gap-y-3 sm:gap-y-0 sm:gap-x-3 lg:gap-x-0 lg:w-1/3">
            <LatestNews />
            <NetWorkCollaboration />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
